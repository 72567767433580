/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axiosInstance from "api/axiosInstance";
import { useState, useEffect } from "react";
import { API_URL } from "../../../../url";

import { toast } from "react-toastify";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

export default function Applications() {
  const [applicationData, setApplicationData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (page) {
      fetchData();
    }
  }, [page]); // Empty dependency array ensures that the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/job/applications?page=${page}&limit=${pageSize}`
      );

      if (response?.data?.success) {
        setApplicationData(response?.data?.data);
        setTotalCount(response?.data?.total);
      } else {
        console.error("Error fetching data:", response?.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const deleteItem = async (id) => {
    const confirmed = window?.confirm("Are you sure want to delete?");

    if (confirmed) {
      const res = await axiosInstance.delete(`${API_URL}/job/applications/${id}`, {});

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        fetchData();
      }
    }
  };
  function downloadBase64File(base64Data, fileName, fileType) {
    const blob = base64toBlob(base64Data, fileType);
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(url);
  }
  function getDateOnly(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }
  function base64toBlob(base64Data, fileType) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: fileType });
  }

  // Example usage:

  const fileName = "example.pdf";
  const fileType = "application/pdf"; // or determine dynamically

  const rows = applicationData
    ? applicationData.map((application) => ({
        Name: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="button" fontWeight="medium">
              {application.name}
            </MDTypography>
          </MDBox>
        ),
        Email: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {application.email}
            </MDTypography>
          </MDBox>
        ),
        PhoneNumber: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {application.phoneNumber}
            </MDTypography>
          </MDBox>
        ),
        ChosenJobProfile: (
          <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
              {application.chosenJobProfile}
            </MDTypography>
          </MDBox>
        ),
        Resume: (
          <MDBox lineHeight={1} textAlign="left">
            <a href="#" onClick={() => downloadBase64File(application.resume, fileName, fileType)}>
              <DownloadForOfflineIcon /> Download Resume
            </a>
          </MDBox>
        ),
        Date: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {getDateOnly(application.createdAt)}
          </MDTypography>
        ),
        Action: (
          <MDBox lineHeight={1} textAlign="left" display="flex" alignItems="center">
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => deleteItem(application?._id)}
            >
              Delete
            </MDButton>
          </MDBox>
        ),
      }))
    : [];
  return {
    columns: [
      { Header: "Name", accessor: "Name", width: "20%", align: "left" },
      { Header: "Email", accessor: "Email", align: "left" },
      { Header: "Phone Number", accessor: "PhoneNumber", width: "30%", align: "center" },
      { Header: "Chosen Job Profile", accessor: "ChosenJobProfile", align: "center" },
      { Header: "Resume", accessor: "Resume", align: "center" },
      { Header: "Date", accessor: "Date", align: "center" },
      { Header: "Action", accessor: "Action", align: "center" },
    ],

    rows: rows,
    totalCount: totalCount,
    pagecount: pageSize,
    setPage: setPage,
  };
}
