/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import axiosInstance from "api/axiosInstance";
import MDButton from "components/MDButton";
import { useState, useEffect } from "react";
import { API_URL } from "../../../../url";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
export default function Data() {
  const [jobData, setJobData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (page) {
      fetchData();
    }
  }, [page]); // Empty dependency array ensures that the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/job/getAlljob`);

      if (response?.data?.success) {
        setJobData(response?.data?.data);
        setTotalCount(response?.data?.totalCount);
      } else {
        console.error("Error fetching data:", response?.data);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const deleteItem = async (id) => {
    const confirmed = window?.confirm("Are you sure want to delete?");

    if (confirmed) {
      const res = await axiosInstance.delete(`/job/delete/${id}`, {});

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        fetchData();
      }
    }
  };
  const isActive = async ({ id, isActive }) => {
    try {
      const res = await axiosInstance.put(`/job/${id}/status`, { isActive });

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.res?.data?.message);
      console.log("", err);
    }
  };
  function getDateOnly(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
    };
    return date.toLocaleDateString(undefined, options);
  }

  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name?.slice(0, 30)}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Email = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );
  const rows = jobData
    ? jobData.map((job) => ({
        Title: <Author name={job.title} />,
        Department: <Email title={job.department} />,

        EmploymentType: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {job.employmentType}
          </MDTypography>
        ),

        Date: (
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            {getDateOnly(job.createdAt)}
          </MDTypography>
        ),
        Action: (
          <MDBox lineHeight={1} textAlign="left" display="flex" alignItems="center">
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={() => deleteItem(job?._id)}
            >
              Delete
            </MDButton>

            <MDBox ml={2}>
              <Link to={"/post-job"} state={{ slug: job?.slug }}>
                <MDButton variant="gradient" color="info" fullWidth>
                  Edit
                </MDButton>
              </Link>
            </MDBox>
            <MDBox ml={2}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                ml={2}
                onClick={() => isActive({ id: job._id, isActive: job?.isActive ? false : true })}
              >
                {job?.isActive ? "Deactivate" : "Activate"}
              </MDButton>
            </MDBox>
            <MDBox ml={2}>
              <Link to={"/job-details"} state={{ slug: job?.slug }}>
                <MDButton variant="gradient" color="info" fullWidth>
                  View
                </MDButton>
              </Link>
            </MDBox>
          </MDBox>
        ),
      }))
    : [];
  return {
    columns: [
      { Header: "Title", accessor: "Title", width: "20%", align: "left" },
      { Header: "Department", accessor: "Department", align: "left" },
      { Header: "EmploymentType", accessor: "EmploymentType", width: "30%", align: "center" },
      { Header: "Date", accessor: "Date", align: "center" },
      { Header: "Action", accessor: "Action", align: "center" },
    ],

    rows: rows,
    totalCount: totalCount,
    pagecount: pageSize,
    setPage: setPage,
  };
}
